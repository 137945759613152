import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import {nivelRota} from "@/core/config/NivelConfig";
import { saveLogAcesso } from "@/services/GeralService";
import {distribuidorRota} from "@/core/config/DistribuidorRouterConfig";
import { formConcessionariaGuard } from '@/router/guard/crudConcessionaria/formConcessionariaGuard';
import { formconsultorGuard } from '@/router/guard/crudConsultor/formConsultorGuard';
import { formGrupoGuard } from '@/router/guard/crudGrupo/formGrupoGuard';
import { formMecanicoGuard } from '@/router/guard/crudMecanico/formMecanicoGuard';
import { formServicoGuard } from '@/router/guard/crudServico/formServicoGuard';
import { formUsuarioGuard } from '@/router/guard/crudUsuario/formUsuarioGuard';
import { adcionarLancamentoMensal } from '@/router/guard/vendas/LancamentoMensal/adcionarLancamentoMensal';
import { precificarGuard } from '@/router/guard/oficinaDigital/andamento/precificarGuard';
import { estoqueGuard } from '@/router/guard/estoque/estoqueGuard';
import { copiarGuard } from "./guard/cardapio/copiarGuard";
import { cardapioGuard } from "./guard/cardapio/cardapioGuard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/agenda-virtual/calendar1/:cod/:cnpj",
    name: "calendar-1",
    meta: {codRequired:[-1, 1, 2, 4, 5]},
    component: () =>
      import("@/views/agenda-virtual/components/multiexibicao/Calendar1.vue"),
  },
  {
    path: "/agenda-virtual/calendar2/:cod/:cnpj",
    name: "calendar-2",
    meta: {codRequired:[-1, 1, 2, 4, 5]},
    component: () =>
      import("@/views/agenda-virtual/components/multiexibicao/Calendar2.vue"),
  },
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/logout",
        name: "logout",
        component: () => {},
      },
      {
        path: "/historico-checklist",
        name: "historico-checklist",
        meta: {codRequired:[1,2,3,-1,6]},
        components: {
          default: () => import("@/views/historico-checklist/HistoricoChecklist.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterHistoricoChecklist.vue"),
          export: () => import("@/layout/header/partials/exports/ExportHistoricoChecklist.vue" ),
       },
      },
      {
        path: "/historico-checklist-stellantis",
        name: "historico-checklist-stellantis",
        meta: {codRequired:[1,2,3,-1,6]},
        components: {
          default: () => import("@/views/historico-checklist-stellantis/HistoricoChecklist.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterHistoricoChecklistStellantis.vue"),
       },
      },
      {
        path: "/oficina-digital/gerenciar",
        name: "gerenciar-oficina-digital",
        meta: {codRequired:[1,2,3,-1]},
        components: {
          default: () => import("@/views/oficina-digital/gerenciar/OficinaDigitalGerenciar.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOficinaDigitalGerenciar.vue"),
       },
      },
      {
        path: "/oficina-digital/prod-gerenciar",
        name: "prod-gerenciar-oficina-digital",
        meta: {codRequired:[1,2,3,-1]},
        beforeEnter() { 
          console.log('oficina digital redirect beforeEnter'); 
          window.location.href = '../v2/oficinadigitallist.php'; 
          return false;
        },
        component: () => {},
      },   
      {
        path: "/oficina-digital/avancar",
        name: "avancar",
        meta: { 
          codRequired: [1,2,3,-1],
        },
        beforeEnter: precificarGuard,          
        component: () => import("@/views/oficina-digital/avancar/OficinaDigitalAvancar.vue"),
      },     
      {
        path: "/oficina-digital/finalizados",
        name: "finalizados",
        meta: {codRequired:[1,2,3,-1]},
        components: {
          default: () => import("@/views/oficina-digital/finalizados/OficinaDigitalFinalizados.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOficinaDigitalFinalizados.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOficinaDigitalFinalizados.vue")
        },
      },
      {
        path: "/oficina-digital/historico",
        name: "historico",
        meta: {codRequired:[1,2,3,-1]},
        components: {
          default: () => import("@/views/oficina-digital/historico/OficinaDigitalHistoricoOrcamentos.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOficinaDigitalHistorico.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOficinaDigitalHistorico.vue"),
        }
      },
      {
        path: "/one-page-report-grupo",
        name: "onepagereportgrupo",
        meta: {codRequired:[1,2,-1]},
        components: {
          default: () => import("@/views/one-page-report-grupo/OnePageReportGrupo.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOnePageReportGrupo.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOnepagePorGrupo.vue"),
        },
      },
            {
        path: "/importarpecaalarcate/:codConcessionaria",
        name: "itensadicionais",
        meta: {codRequired:[1,2,-1]},
        components: {
          default: () => import("@/views/cardapio/ItensAdicionais.vue"),
        },
      },
      {
        path: "/one-page-report-marca",
        name: "onepagereportmarca",
        meta: {codRequired:[1,2,-1]},
        components: {
          default: () => import("@/views/one-page-report-marca/OnePageReportMarca.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOnePageReportMarca.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOnepagePorMarca.vue"),
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {codRequired:[1,2,-1]},
        components: {
          default: () => import("@/views/Dashboard.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterDashboard.vue"),
          export: () => import("@/layout/header/partials/exports/ExportDashboard.vue"),
        },
      },
      {
        path: "/oficina-digital/reprovados",
        name: "oficina-digital-reprovados",
        meta: {codRequired:[1,2,3,-1]},
        components: {
          default: () => import("@/views/oficina-digital/reprovados/OficinaDigitalReprovados.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterOficinaDigitalReprovados.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOficinaDigitalReprovados.vue"),
        }
      },
      {
        path: "/oficina-digital/precificar",
        name: "oficina-digital-precificar",
        meta: {
          codRequired: [1, 2, 3, -1],
        },
        beforeEnter: precificarGuard,
        component: () =>
          import("@/views/oficina-digital/precificar/OficinaDigitalPrecificar.vue"),
      },
      {
        path: "/cadastros/concessionaria/list",
        name: "cadastros-concessionaria-list",
        meta: { codRequired:[2,-1], hiddenHeader: true },
        components:{
          default: () => import("@/views/crud/concessionaria/ListagemConcessionaria.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterCrudCadastro.vue"),
        }
      },
      {
        path: "/cadastros/concessionaria/form/:concessionaria?",
        name: "cadastros-concessionaria-form",
        meta: {
          codRequired:[2,-1]         
        },
        beforeEnter: formConcessionariaGuard,
        component: () =>
          import("@/views/crud/concessionaria/AdicionarConcessionaria.vue"),
      },
      {
        path: "/cadastros/grupos/list",
        name: "cadastros-grupos-list",
        meta: {codRequired:[2,-1]},
        components:{
          default: () => import("@/views/crud/grupos/Gruposlist.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterGrupos.vue"),
        }
      },  
      {
        path: "/cadastros/grupos/form",
        name: "cadastros-grupos-form",
        meta: {codRequired:[2,-1]},
        beforeEnter: formGrupoGuard,
        component: () =>
          import("@/views/crud/grupos/AdicionarGrupo.vue"),
      },
      {
        path: "/cadastros/servicos/list",
        name: "cadastros-servicos-list",
        meta: {codRequired: [1, 2, 3, -1]},
        components: {
          default: () => import("@/views/crud/servicos/ServicoList.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterServicos.vue"),
          export: () => import("@/layout/header/partials/exports/ExportServicos.vue" )
        }
      },
      {
        path: "/cadastros/servicos/form",
        name: "cadastros-servicos-form",
        beforeEnter: formServicoGuard,
        meta: {codRequired: [1, 2, 3, -1]},
        components: {
          default: () => import("@/views/crud/servicos/ServicoFormV6.vue")
        }
      },
      {
        path: "/cadastros/mecanicos/list",
        name: "cadastros-mecanicos-list",
        meta: {codRequired: [1, 2, 3, -1]},
        components: {
          default: () => import("@/views/crud/mecanicos/MecanicosList.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterMecanicos.vue")
        }
      },
      {
        path: "/cadastros/mecanicos/form",
        name: "cadastros-mecanicos-form",
        beforeEnter: formMecanicoGuard,
        meta: {codRequired: [1, 2, 3, -1]},
        components: {
          default: () => import("@/views/crud/mecanicos/MecanicoForm.vue")
        }
      },
      {
        path: "/cadastros/consultores/list",
        name: "cadastros-consultores-list",
        meta: {codRequired: [1, 2, 3, -1]},
        components: {
          default: () => import("@/views/crud/consultores/ConsultoresList.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterConsultores.vue")
        }
      },
      {
        path: "/cadastros/consultores/form",
        name: "cadastros-consultores-form",
        meta: {codRequired: [1, 2, 3, -1]},
        beforeEnter: formconsultorGuard,
        components: {
          default: () => import("@/views/crud/consultores/ConsultoresForm.vue"),
        }
      },
      {
        path: "/cadastros/usuario/list",
        name: "cadastros-usuario-list",
        meta: {codRequired: [ 2, -1], hiddenHeader: true },
        components: {
          default: () => import("@/views/crud/usuarios/UsuarioList.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterUsuarios.vue") 
        }
      },
      {
        path: "/cadastros/usuarios/form",
        name: "cadastros-usuarios-form",
        beforeEnter: formUsuarioGuard,
        meta: { codRequired:[2,-1], hiddenHeader: true },
        components:{
          default: () => import("@/views/crud/usuarios/UsuarioForm.vue")
        }
      },
      {
        path: "/cadastros/usuarios/filtros/form",
        name: "cadastros-usuarios-filtros-form",
        meta: {
          codRequired: [2, -1],
          paramsRequired: {
            required: ["codUsuario"],
            backRoute: "cadastros-usuario-list"
          }, 
          hiddenHeader: true
        },
        components:{
          default: () => import("@/views/crud/usuarios/UsuarioFiltrosForm.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterCrudCadastro.vue"),
        }
      },
      {        
          path: "/sugestaoPedidos",
          name: "sugestao-pedidos",
          meta: { codRequired:[1,2,-1]},
          components:{
            default: () => import("@/views/pedidos/sugestaoPedidos.vue"),
            filter: () => import("@/layout/header/partials/filters/FilterConsultoresPedidos.vue")
        },
      },
      {        
        path: "/estoque",
        name: "estoque",
        beforeEnter: estoqueGuard,
        meta: { codRequired:[1,2,-1], paramRequired: { required: ['byPush'], backRoute: "sugestao-pedidos" } },
        components: {
            default: () => import("@/views/estoque/Estoque.vue"),
            filter: () => import("@/layout/header/partials/filters/FilterEstoque.vue"),
            export: () => import("@/layout/header/partials/exports/ExportEstoque.vue" )
        },
        props: {
            default: true,
            export: true,
            filter: true
        }
      },      
      {        
        path: "/ordemservicogerenciar",
        name: "ordem-servico-gerenciar",
        meta: { codRequired: [-1,  2,  1]},
        components:{
          default: () => import("@/views/listaDeOS/OrdemServicoGerenciar.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterListaOS.vue"),
          export: () => import("@/layout/header/partials/exports/ExportOrdemServicoGerenciar.vue")
      },
    },  
    {
      path: "/vendas/lancamento-mensal",
      name: "lancamento-mensal",
      meta: { codRequired: [2, -1 , 1] },
      components:{
        default: () => import("@/views/vendas/lancamento-mensal/LancamentoMensalList.vue"),
        filter: () => import("@/layout/header/partials/filters/FilterLancamentoMensal.vue"),
      }
    },
    {
      path: "/vendas/integracao-dms",
      name: "integracao-dms",
      meta: { codRequired: [2, -1] },
      components:{
        default: () => import("@/views/vendas/integracao-dms/IntegracaoDms.vue"),
        filter: () => import("@/layout/header/partials/filters/FilterIntegracaoDms.vue"),
      }
    },
    {
      path: "/vendas/relatorio-excel",
      name: "relatorio-excel",
      meta: { codRequired: [2, -1], hiddenHeader: true },
      components:{
        default: () => import("@/views/vendas/relatorio-excel/RelatorioExcel.vue"),
      }
    },
    {
      path: "/vendas/lancamento-mensal/novo-lancamento",
      name: "novo-lancamento-mensal",
      beforeEnter: adcionarLancamentoMensal,
      meta: { codRequired: [2, -1 , 1]},
      components:{
        default: () => import("@/views/vendas/lancamento-mensal/LancamentoMensalForm.vue"),
      }
    },
    {
        path: "/vendas/lancamento-mensal/workflow",
        name: "lancamento-mensal-workflow",
        meta: { 
          codRequired: [2, -1 , 1],
          hiddenHeader: true, 
         },        
        components:{
            default: () => import("@/views/vendas/importacao-workflow/importacaoWorkflow.vue"),
        }
    },
      {
        path: "/ferramentas-desenvolvedor/gerenciar-checklist",
        name: "ferramentas-desenvolvedor",
        meta: {
          hiddenHeader: true, 
          codRequired: [-1],
          distribuidorRequired: ["atria", "homologacao", "localhost"]
        },
        component: () =>
          import("@/views/ferramentas-desenvolvedor/gerenciar-checklist/GerenciarChecklist.vue"),
      },
      {
        path: "/ferramentas-desenvolvedor/gerar-checklist",
        name: "ferramentas-desenvolvedor-gerar",
        meta: {
          hiddenHeader: true,
          codRequired: [-1],
          distribuidorRequired: ["atria", "homologacao", "localhost"]
        },
        component: () =>
          import("@/views/ferramentas-desenvolvedor/gerar-checklist/FormChecklist.vue"),
      },
      {
        path: "/ferramentas-desenvolvedor/configuracoes",
        name: "configuracao-app",
        meta: {
          codRequired: [-1],
          distribuidorRequired: ["atria", "homologacao", "localhost"]
        },        
        components:{
          default: () => import("@/views/ferramentas-desenvolvedor/configuracaoApp/ConfigApp.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterConfigApp.vue"),
      },
      },
      {
        path: "/calculadora-precos",
        name: "calculadora-precos",
        meta: {
          hiddenHeader: true,
          codRequired: [-1],
          distribuidorRequired: ["atria", "homologacao", "localhost"]
        },
        component: () =>
          import("@/views/calculadora-precos/CalculadoraPrecos.vue"),
      },
      {
        path: "/calculadora-precos-config",
        name: "calculadora-precos-config",
        meta: {
          hiddenHeader: true,
          codRequired: [-1],
          distribuidorRequired: ["atria", "homologacao", "localhost"]
        },
        component: () =>
          import("@/views/calculadora-precos/CalculadoraPrecosConfiguracao.vue"),
      },
      {
        path: "/agenda-virtual/:tipo?",
        name: "agenda-virtual",
        meta: {codRequired:[-1, 1, 2, 4, 5], contentFluid: true },
        components: {
          default: () => import("@/views/agenda-virtual/AgendaVirtual.vue"),
          filter: () => import("@/layout/header/partials/filters/agenda-virtual/FilterAgenda.vue"),
        },
      },
      // {
      //   path: "/agenda-virtual/agendar",
      //   name: "agenda-virtual-agendar",
      //   redirect: to => ({
      //     name: "agenda-virtual",
      //     params: { view: "programacao" },
      //   }),
      // },
      // {
      //   path: "/agenda-virtual/exibir",
      //   name: "agenda-virtual-exibir",
      //   redirect: to => ({
      //     name: "agenda-virtual",
      //     params: { view: "servicos" },
      //   }),
      // },
      // {
      //   path: "/agenda-virtual/exibir-consultor",
      //   name: "agenda-virtual-exibir-consultor",
      //   redirect: to => ({
      //     name: "agenda-virtual",
      //     params: { view: "agendamentos" },
      //   }),
      // },
      // {
      //   path: "/agenda-virtual/recepcao",
      //   name: "agenda-virtual-recepcao",
      //   redirect: to => ({
      //     name: "agenda-virtual",
      //     params: { view: "recepcao" },
      //   }),
      // },
      // {
      //   path: "/agenda-virtual/exibir-multiview",
      //   name: "agenda-virtual-exibir-multiview",
      //   meta: {codRequired:[-1, 1, 2, 4, 5], contentFluid: true},
      //   components: {
      //     default: () =>import("@/views/agenda-virtual/AgendaVirtualMultiexibicao.vue"),
      //     filter: () => import("@/layout/header/partials/filters/agenda-virtual/FilterMultiview.vue"),
      //   }     	
      // },
      {
        path: "/business-inteligence",
        name: "business-inteligence",
        meta: { codRequired:[2,-1,1], hiddenHeader: true },
        components:{
          default: () => import("@/views/business-inteligence/BusinessInteligence.vue"),
          filter: () => import("@/layout/header/partials/filters/FilterBusinessInteligence.vue"),
        }
      },
      {
        path: "/log-detalhado/",
        name: "log-detalhado",
        meta: { codRequired:[2,-1], hiddenHeader: true },
        components:{
           default: () => import("@/views/log-acessos/logDetalhado/LogUserDetalhado.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterLogDetalhado.vue"),
        }
      },
      {
        path: "/log-acessos/",
        name: "log-acessos",
        meta: { codRequired:[2,-1,1] },
        components:{
           default: () => import("@/views/log-acessos/logApp/LogAcessosApp.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterLogAcessosApp.vue"),
        }
      },
      {
        path: "/lista-acessos/",
        name: "lista-acessos",
        meta: { codRequired:[2,-1], hiddenHeader: true },
        components:{
           default: () => import("@/views/log-acessos/logLista/LogLista.vue"), 
           filter: () => import("@/layout/header/partials/filters/Logs/FilterLogLista.vue"),
        }
      },
      {
        path: "/arquivos-exportado/",
        name: "arquivos-exportado",
        meta: { codRequired:[2,-1] },
        components:{
           default: () => import("@/views/log-acessos/arquivosExportados/ArquivosExportados.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterArquivosExportados.vue"),
        }
      },
      {
        path: "/relatorio/fechamento-mensal",
        name: "fechamento-mensal",
        meta: { codRequired:[2,-1] },
        components:{
           default: () => import("@/views/relatorios/fechamento-Mensal/FechamentoMensal.vue"), 
        }
      },
      {
        path: "/relatorio/historico-mensal",
        name: "historico-mensal",
        meta: { codRequired:[2,-1], hiddenHeader: true },
        components:{
           default: () => import("@/views/relatorios/historico-Mensal/HistoricoMensal.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterRelatorioHistoricoMensal.vue"),
        }
      },
      {
        path: "/cardapio/:tab?",
        name: "cardapio",
        meta: { codRequired:[2,-1],contentFluid: true},
        beforeEnter: cardapioGuard,
        components:{
           default: () => import("@/views/cardapio/CardapioInit.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterCardapio.vue"),
        }             
      },
      {
        path: "/listarItens",
        name: "listarItens",
        meta: { codRequired:[2,-1],contentFluid: true},
        components:{
           default: () => import("@/views/cardapio/cardapioListaItens/ListarItens.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterCardapio.vue"),
        }             
      },
      {
        path: "/listarPacote",
        name: "listarPacote",
        meta: { codRequired:[2,-1],contentFluid: true},
        components:{
           default: () => import("@/views/cardapio/listarPacotes/ListarPacote.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterCardapio.vue"),
        }             
      },
      {
        path: "/listarItensAgregado",
        name: "listarItensAgregados",
        meta: { codRequired:[2,-1],contentFluid: true},
        components:{
           default: () => import("@/views/cardapio/listarItemAgregados/ListarItem.vue"), 
           filter: () => import("@/layout/header/partials/filters/FilterCardapio.vue"),
        }             
      },
      {
        path: "/cardapio/copiar/:tipoCopia",
        name: "cardapioCopiar",
        meta: { codRequired:[2,-1],contentFluid: false},
        components:{
           default: () => import("@/views/cardapio/Copiar/Copiar.vue"), 
        },
        beforeEnter: copiarGuard
      },
      {
        path: "/cardapio/adicionar/pacote/:codPacote?",
        name: "add-package",
        meta: { 
          codRequired:[2,-1]
        },
        components:{
          default: () => import("@/views/cardapio/addItemPackage/AddItemPackage.vue"), 
       }
      },      
      {
        path: "/cardapio/itensAdicionais/adicionar/:codAlacarte?",
        name: "itensAdicionais-adicionar",
        meta: { 
          codRequired:[2,-1]
        },
        components:{
          default: () => import("@/views/cardapio/ItensAdicionais/components/AdicionarItem.vue"), 
       }
      },
      {
        path: "/cardapio/pneu/adicionar/:codPneus?",
        name: "pneu-adicionar",
        meta: { 
          codRequired:[2,-1]
        },
        components:{
          default: () => import("@/views/cardapio/pneu/components/AdicionarPneu.vue"), 
       }
      },
      {
        path: "/cardapio/manual/adicionar/:codManualProprietario?",
        name: "manual-adicionar",
        meta: { 
          codRequired:[2,-1]
        },
        components:{
          default: () => import("@/views/cardapio/manualdoProprietario/components/AdicionarManual.vue"), 
       }
      },
      {
        path: "/cardapio/importar/:type",
        name: "cardapio-importar",
        meta: { 
          codRequired:[2,-1]
        },
        components:{
          default: () => import("@/views/cardapio/excelImport/Import.vue"), 
       }
      }
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Login.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import("@/views/LoginTv.vue"),
      },
      {
        path: "/password/:token",
        name: "password-reset",
        component: () => import("@/views/PasswordReset.vue"),
      }
    ],
  },
  {
    // the 401 route, when none of the above matches
    path: "/401",
    name: "401",
    component: () => import("@/views/Error401.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/erroConcessionaria",
    name: "erroConcessionaria",
    component: () => import("@/views/ErrorConcessionaria.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  // console.log('beforeEach 336 to',to);
  // console.log('beforeEach 336 from',from);
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.LOGADO);
  // const canAccess = await store.getters.getLogado;
  // console.log('canAccess',canAccess);
  document.body.classList.add("page-loading");
  document.body.classList.remove("modal-open");
  document.body.style.overflow = "auto";
  document.body.style.paddingRight = "0";
  

  await store.dispatch(Actions.LOGADO).then(() => {
    const distribuidor = store.getters.layoutConfig("distribuidor").distribuidor;
    let codNivel = store.getters.getCodNivel;

    if(to.name == "logout")
    {
      // console.log('chegou no logout da rota');
      store.dispatch(Actions.LOGOUT);
      return false;
    }
    else if (to.name !== 'login' && !store.getters.isUsuarioLogado && to.name !== 'password-reset'){
      // console.log('nao autenticado 342');
      router.push({ name: "login" });
      return false;
    }
    else if(to.name == 'login' && store.getters.isUsuarioLogado)
    {
        if(distribuidorRota[distribuidor]){
            router.push({name: distribuidorRota[distribuidor]})
        }else{
            router.push({ name: nivelRota[codNivel] });
        }

      return false;
    }
    else if(store.getters.isUsuarioLogado)
    {
      try {
        let params = 'type=view';
        
        const auxTo:any = to;
        const auxArrayKeys = Object.keys(to.params);
        auxArrayKeys.forEach(key => {
          params += `&${key}=${to.params[key]}`
        });
        
        if(!auxArrayKeys.includes("codConcessionaria")){
          let auxCodConcessionaria = store.getters.concessionariaSelectedInfo?.codConcessionaria ?? localStorage.getItem("filtroConcessionaria");
          auxCodConcessionaria = auxCodConcessionaria ?? null;

          params += `&codConcessionaria=${auxCodConcessionaria}`
        }

        const postData = {
          pagina: auxTo.href,
          params
        };

        saveLogAcesso(postData);
      } catch (e){
        console.error(e);
      }
      
      // console.log('logado e autenticado e outros 145');
      if(to.meta.contentFluid)
      {
        store.dispatch("setContentType", "fluid")
      } else {
        store.dispatch("setContentType", "fixed")
      }

      if(to.meta.codRequired)
      {
        const codRequired:any = to.meta.codRequired;
        if(!codRequired.includes(codNivel))
        {
          router.push({ name: "401" });
          return false;
        }
      }

      // if(to.meta.paramsRequired)
      // {
      //   let paramsRequired:any = to.meta.paramsRequired;
      //   if(paramsRequired.required.some(paramRequired => !to.params[paramRequired])){
      //     router.push({ name: paramsRequired.backRoute });
      //   }
      // }

      if(to.meta.distribuidorRequired)
      {
        let distribuidorRequired:any = to.meta.distribuidorRequired;
        if(!distribuidorRequired.includes(distribuidor)){
          router.push({ name: "404" });
        }
      }
    }
    // console.log('action terminou');
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  document.body.classList.remove("page-loading");
});

export default router;
