import { defineStore } from 'pinia';

export const useSelectedConcessionariaStore = defineStore('useSelectedConcessionariaStore', {
  state: () => ({
    selectedConcessionaria: null as string | null,
    isPush: false as boolean,
    timeStep: 0,
  }),
  actions: {
    setByPush(value: boolean) {
      this.isPush = value;
    },
    setSelectedConcessionaria(codConcessionaria: string | null) {
      this.selectedConcessionaria = codConcessionaria
      this.setByPush(true)
    },
    clearSelectedConcessionaria(){
      this.selectedConcessionaria = null
    },
    setTimeStep(value: number) {
      this.timeStep = value
    }
  },
  getters: {
    getSelectedConcessionaria: (state) => state.selectedConcessionaria,
    byPush: (state) => state.isPush
  },
});

export type ConcessionariaStore = ReturnType<typeof useSelectedConcessionariaStore>;
